var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question-container" },
    [
      _vm.loading
        ? _c("v-progress-circular", {
            staticClass: "mt-10",
            attrs: { color: "inqliPurple", indeterminate: "" }
          })
        : _c(
            "div",
            [
              _c("question-detail", {
                attrs: { questionDetail: _vm.questionDetail },
                on: { reload: _vm.getQuestion }
              })
            ],
            1
          ),
      _c("div")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }