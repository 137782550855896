<template>
  <div class="question-container">
    <v-progress-circular
      class="mt-10"
      v-if="loading"
      color="inqliPurple"
      indeterminate
    ></v-progress-circular>
    <div v-else>
      <question-detail :questionDetail="questionDetail" @reload="getQuestion" />
    </div>
    <div></div>
  </div>
</template>

<script>
import QuestionDetail from "./components/question-detail/QuestionDetail.vue";
import sendRequest from "../app/utils/send-request-helper";

export default {
  data() {
    return {
      loading: true,
      questionDetail: {},
      questionId: this.$route.params.id,
    };
  },
  metaInfo: {
    title: "Question Details",
  },
  components: {
    "question-detail": QuestionDetail,
  },
  methods: {
    async getQuestion() {
      try {
        const data = await sendRequest({
          url: `/pages/question-details/${this.questionId}`,
          method: "GET",
          isAuth: false,
        });
        this.loading = false;
        this.questionDetail = data;
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting question details."
        );
      }
    },
  },
  async mounted() {
    await this.getQuestion();
  },
  async beforeRouteUpdate(to, from, next) {
    // this is to make sure the question detail card is always updated
    // after navigating from another question detail page
    next();
    this.questionId = to.params.id;
    await this.getQuestion();
  },
};
</script>

<style lang="scss" scoped>
.question-container {
  margin-top: 16px;
  background-color: transparent;
  .loading {
    margin-top: 200px;
  }
  .question-details {
    text-align: left;
  }
}
</style>
